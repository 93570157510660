import { IEnv } from './env.type'

const hostProd = 'api.denistiaglin.com'
const localPath = window.location.hostname.replace('my', 'api')
const currentPatch = window.location.hostname === 'localhost' ? hostProd : localPath
export const environment: IEnv = {
  PRODUCTION: true,
  API_PREFIX: `https://${currentPatch}`,
  SOCKET_PREFIX: `wss://${currentPatch}`,
}
