import React, { SVGAttributes } from 'react'

export const SecurityCheck: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#347DFE" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C21.882 0 24 2.118 24 12C22 22 12 24 12 24C12 24 2 22 0 12C0 2.118 2.118 -1.91298e-06 12 0ZM16.7071 8.29291C17.0976 8.68343 17.0976 9.3166 16.7071 9.70712L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29291 12.7071C7.90238 12.3166 7.90238 11.6834 8.29291 11.2929C8.68343 10.9024 9.3166 10.9024 9.70712 11.2929L11 12.5858L15.2929 8.29291C15.6834 7.90238 16.3166 7.90238 16.7071 8.29291Z"
    />
  </svg>
)
