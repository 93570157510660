import React, { SVGAttributes } from 'react'

export const TiaglinHub: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    color="347DFE"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.810791 4.21631H5.99998V9.4055H0.810791V4.21631ZM5.99998 4.21631H11.1892V9.4055H5.99998V4.21631ZM18.1077 4.21631H23.2969V9.4055H18.1077V4.21631ZM11.1892 14.5947H5.99998V19.7839H11.1892V14.5947ZM18.1077 14.5947H23.2969V19.7839H18.1077V14.5947ZM14.6482 14.5947V14.5947H18.1077V14.5947H23.2969V9.40552H18.1077V9.40554H14.6482L12.9185 14.5947H14.6482ZM11.1892 9.40552H5.99998V14.5947H11.1892V9.40552ZM16.3784 4.21631H11.1892V9.4055H14.6486L14.6486 9.40549L16.3784 4.21633L16.3784 4.21631Z"
    />
  </svg>
)
