import React, { SVGAttributes } from 'react'

export const ID: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 13V0H2.77398V13H0Z" />
    <path
      d="M5.29559 13V0H10.2351C12.3827 0 14.0471 0.565485 15.2282 1.69645C16.4094 2.81513 17 4.41324 17 6.49078C17 8.56832 16.4094 10.1726 15.2282 11.3035C14.0471 12.4345 12.3827 13 10.2351 13H5.29559ZM8.06957 10.6582H10.074C12.7585 10.6582 14.1007 9.26903 14.1007 6.49078C14.1007 3.72482 12.7585 2.34184 10.074 2.34184H8.06957V10.6582Z"
    />
  </svg>
)
