import React, { SVGAttributes } from 'react'

export const CheckMark: React.FC<SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.24"
      d="M20 2.3094C22.4752 0.880339 25.5248 0.880339 28 2.3094L40.7846 9.6906C43.2598 11.1197 44.7846 13.7607 44.7846 16.6188V31.3812C44.7846 34.2393 43.2598 36.8803 40.7846 38.3094L28 45.6906C25.5248 47.1197 22.4752 47.1197 20 45.6906L7.21539 38.3094C4.74018 36.8803 3.21539 34.2393 3.21539 31.3812V16.6188C3.21539 13.7607 4.74018 11.1197 7.21539 9.6906L20 2.3094Z"
      fill="#ACCDFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C33.882 12 36 14.118 36 24C34 34 24 36 24 36C24 36 14 34 12 24C12 14.118 14.118 12 24 12ZM28.7071 20.2929C29.0976 20.6834 29.0976 21.3166 28.7071 21.7071L23.7071 26.7071C23.3166 27.0976 22.6834 27.0976 22.2929 26.7071L20.2929 24.7071C19.9024 24.3166 19.9024 23.6834 20.2929 23.2929C20.6834 22.9024 21.3166 22.9024 21.7071 23.2929L23 24.5858L27.2929 20.2929C27.6834 19.9024 28.3166 19.9024 28.7071 20.2929Z"
      fill="#347DFE"
    />
  </svg>
)
