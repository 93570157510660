import React, { SVGAttributes } from 'react'

export const ArrowWithStickRight: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#347DFE" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7072 5.29289L13.293 6.70711L17.5859 11H5.00009C4.44781 11 4.00009 11.4477 4.00009 12C4.00009 12.5523 4.44781 13 5.00009 13H17.5859L13.293 17.2929L14.7072 18.7071L21.4143 12L14.7072 5.29289Z"
    />
  </svg>

)
