import React, { SVGAttributes } from 'react'

export const Wallet: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#347DFE" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9C2 6.17157 2 4.75736 2.87868 3.87868C3.75736 3 5.17157 3 8 3H16C18.8284 3 20.2426 3 21.1213 3.87868C22 4.75736 22 6.17157 22 9V11H16C14.3431 11 13 12.3431 13 14C13 15.6569 14.3431 17 16 17H22C22 17.93 22 18.395 21.8978 18.7765C21.6204 19.8117 20.8117 20.6204 19.7765 20.8978C19.395 21 18.93 21 18 21H8C5.17157 21 3.75736 21 2.87868 20.1213C2 19.2426 2 17.8284 2 15V9ZM5 8C5 7.44772 5.44772 7 6 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H6C5.44772 9 5 8.55228 5 8ZM16 13C15.4477 13 15 13.4477 15 14C15 14.5523 15.4477 15 16 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H16Z"
    />
  </svg>
)
