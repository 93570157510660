import React, { SVGAttributes } from 'react'

export const Logo: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5905 4.58356C24.9881 3.66128 23.016 3.66128 21.4135 4.58356L8.44053 12.0501C6.83156 12.9761 5.83984 14.6911 5.83984 16.5476V31.4526C5.83984 33.309 6.83156 35.024 8.44053 35.9501L21.4135 43.4166C23.016 44.3389 24.9881 44.3389 26.5905 43.4166L39.5635 35.9501C41.1725 35.024 42.1642 33.309 42.1642 31.4526V16.5476C42.1642 14.6912 41.1725 12.9761 39.5635 12.0501L26.5905 4.58356ZM15.1998 16.2001H20.3998V31.8001H15.1998V16.2001ZM21.9998 16.2001H27.1998H30.6398H32.389H35.8398L33.6598 22.7001L33.3873 23.5126L33.2511 23.9188L33.183 24.1219L33.1148 24.3251L32.5698 25.9501L31.4798 29.2H27.1998V31.8001H21.9998V16.2001ZM28.8958 21.4001H27.1998L27.1998 26.4569L28.8958 21.4001Z"
      fill="#347DFE"
    />
  </svg>
)
