import React, { SVGAttributes } from 'react'

export const Main: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 18" fill="none" {...props}>
    <path d="M8.01611 3.64688C8.01611 5.65821 6.3856 7.28872 4.37427 7.28872C2.36293 7.28872 0.732422 5.65821 0.732422 3.64688C0.732422 1.63554 2.36293 0.0050354 4.37427 0.0050354C6.3856 0.0050354 8.01611 1.63554 8.01611 3.64688Z" fill="#347DFE" />
    <path d="M18.7223 14.3531C18.7223 16.3645 17.0918 17.995 15.0805 17.995C13.0691 17.995 11.4386 16.3645 11.4386 14.3531C11.4386 12.3418 13.0691 10.7113 15.0805 10.7113C17.0918 10.7113 18.7223 12.3418 18.7223 14.3531Z" fill="#347DFE" />
    <g opacity="0.4">
      <path d="M18.7223 3.64688C18.7223 5.65821 17.0918 7.28872 15.0805 7.28872C13.0691 7.28872 11.4386 5.65821 11.4386 3.64688C11.4386 1.63554 13.0691 0.0050354 15.0805 0.0050354C17.0918 0.0050354 18.7223 1.63554 18.7223 3.64688Z" fill="#347DFE" />
      <path d="M8.01611 14.3531C8.01611 16.3645 6.3856 17.995 4.37427 17.995C2.36293 17.995 0.732422 16.3645 0.732422 14.3531C0.732422 12.3418 2.36293 10.7113 4.37427 10.7113C6.3856 10.7113 8.01611 12.3418 8.01611 14.3531Z" fill="#347DFE" />
    </g>
  </svg>
)
