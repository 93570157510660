import React, { SVGAttributes } from 'react'

export const Moneybox: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#347DFE" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C7.34315 2 6 3.34315 6 5V19C6 20.6569 7.34315 22 9 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H9ZM4 6H2V10H4V6ZM2 14H4V18H2V14ZM15 6H13V8.12602C11.5944 8.4878 10.4878 9.59439 10.126 11H8V13H10.126C10.4878 14.4056 11.5944 15.5122 13 15.874V18H15V15.874C16.4056 15.5122 17.5122 14.4056 17.874 13H20V11H17.874C17.5122 9.59439 16.4056 8.4878 15 8.12602V6ZM16 12C16 13.1046 15.1046 14 14 14C12.8954 14 12 13.1046 12 12C12 10.8954 12.8954 10 14 10C15.1046 10 16 10.8954 16 12Z"
    />
  </svg>
)
